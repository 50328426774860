.clear {
  clear: 'both'; }

.orders-report .message-style {
  font-size: 100px;
  text-align: center;
  margin-top: 150px;
  font-weight: bold;
  font-family: cursive; }

.orders-report .search-element {
  float: left;
  margin-right: 10px;
  margin-left: 10px; }

.orders-report .orders-report .search-element button {
  min-width: 170px; }

.DateInput_input {
  padding: 0px;
  padding-left: 8px; }

.DateRangePickerInput__withBorder {
  border-radius: 4px;
  padding: 3px; }

.orders-report .DateInput {
  width: 108px; }

.orders-report #headerRow {
  background: #efe;
  margin-top: 10px; }

.orders-report #headerRow span {
  float: left;
  padding: 4px;
  font-weight: bold;
  width: 99px; }

.clickable {
  cursor: pointer; }

.orders-report .dataRow {
  border-bottom: 1px solid #ddd; }

.orders-report .dataRow.odd {
  background: #f4f4f4; }

.orders-report .dataRow.even {
  background: #fcfcfc; }

.orders-report .dataRow:hover {
  background: #eee; }

.inner.odd {
  background: #ebf9fd;
  border-bottom: 1px solid #7cbcd1; }

.inner.even {
  background: #e2f8fe;
  border-bottom: 1px solid #7cbcd1; }

.inner:hover {
  background: #d3f5ff; }

.dataRow .red {
  color: red; }

.dataRow .green {
  color: green; }

.orders-report .dataRow span.completed,
.orders-report .dataRow .inner span {
  float: left;
  padding: 4px;
  width: 99px;
  background: none; }

.orders-report #headerRow span.aov,
.orders-report .dataRow span.aov,
.orders-report #headerRow span.status,
.orders-report .dataRow span.status {
  width: 150px; }

.orders-report #headerRow span.tax,
.orders-report .dataRow span.tax {
  width: 'fit-content'; }

.clear {
  clear: both; }

.white {
  background: #fff; }

.order-processing .search {
  width: 100%;
  float: left;
  padding-bottom: 10px;
  border-bottom: 1px solid #bbb; }

/******************************************
* the list section
*******************************************/
.order-processing .list {
  overflow-x: scroll;
  background: #fafafa;
  padding: 4px;
  transition: 700ms; }

.order-processing .list .message-style {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 20px; }

.order-processing .list tr {
  cursor: pointer; }

.order-processing .list tr.selected {
  font-weight: bold;
  background: #ffe; }

.order-processing .list .order-count {
  background: #ebf9fd;
  border: 1px solid #7cbcd1;
  border-radius: 5px;
  text-align: center;
  padding: 10px;
  color: #aaa;
  white-space: nowrap; }

.order-processing .list .manual-order {
  text-align: center; }

/******************************************
* the details section
*******************************************/
.order-processing .details {
  right: 0px;
  top: 117px;
  position: absolute;
  transition: 700ms;
  border-left: 1px solid #bbb;
  background: #f6f6f6;
  padding-bottom: 400px; }

.order-processing .details .message-style {
  text-align: center;
  margin-top: 150px;
  margin-bottom: 650px; }

/*dealing with the tabs*/
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  border: 1px solid #aaa;
  border-bottom-color: transparent; }

#order-details-holder {
  margin: 10px;
  width: 711px; }

.nav-tabs {
  border-bottom: 1px solid #aaa; }

.order-processing .customer-order {
  padding: 10px;
  border: 1px solid #aaa;
  margin-right: 10px;
  border-top: none;
  background: #fff;
  width: 711px;
  float: left;
  margin-bottom: 10px; }

.order-processing .customer-order .options-table {
  float: right; }

.order-processing .customer-order .options-table td {
  padding: 3px; }

.order-processing .details .form-control {
  height: 25px;
  padding: 5px; }

.order-processing .details .DateInput {
  width: 96px; }

.order-processing .details .DateInput_input {
  font-size: 14px;
  font-weight: inherit;
  color: #555;
  padding-left: 4px;
  line-height: 100%;
  width: 96px; }

.order-processing .details .SingleDatePickerInput__withBorder {
  border-radius: 4px;
  padding: 1px; }

.order-processing .details .status_message {
  color: #999;
  font-style: italic;
  font-size: 12px;
  display: inline;
  position: relative;
  top: -5px;
  left: 5px; }

.order-processing .customer-order .dropdown .btn {
  padding: 0px 8px 0px 8px; }

.order-processing .details input {
  width: 100px;
  display: inline; }

input.dirty,
button.dirty {
  background: #fee; }

.heidi .action-button {
  margin-bottom: 10px; }

.action-button {
  border: none;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 10px; }

.action-button.orange {
  background-color: #ef4d21 !important; }

.action-button.green {
  background-color: #067038 !important; }

.action-button.refund_item {
  background-color: #bbb !important;
  border: 1px solid #aaa;
  padding: 2px;
  margin-top: 10px; }

.refund-info {
  background: #fdd;
  border: 1px solid #faa;
  padding: 4px;
  border-radius: 5px;
  text-align: center; }

.action-button:disabled {
  background-color: #ccc !important; }

.action-button:hover {
  color: #fff;
  text-decoration: underline; }

.order-processing .wholesale {
  text-align: center;
  background: #ebf9fd;
  border: 1px solid #7cbcd1;
  padding: 5px;
  margin-top: 5px;
  border-radius: 5px;
  position: absolute;
  bottom: 5px;
  width: 125px; }

.order-processing .wholesale.last {
  border-radius: 5px 5px 0px 0px;
  bottom: 0px; }

.order-processing .col1 {
  width: 125px;
  margin: 0px 10px 0px 0px;
  float: left;
  position: relative; }

.order-processing .col1 img {
  width: 125px; }

.order-processing .orderitem .col2 {
  width: 260px;
  margin: 5px;
  float: left; }

.order-processing .orderitem .col3 {
  width: 250px;
  margin: 5px;
  float: left; }

.order-processing .orderitem .postCols {
  clear: left;
  display: grid;
  grid-template-columns: 140px 80px auto;
  padding: 8px; }
  .order-processing .orderitem .postCols header {
    grid-column: 2; }
  .order-processing .orderitem .postCols textarea {
    grid-column: 3;
    height: auto; }

.order-processing .right {
  padding-right: 5px;
  text-align: right; }

.order-processing .fieldLabel {
  font-size: 13px;
  color: #888; }

.order-processing .suborder {
  background: #f6f6f6;
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px; }

.order-processing .suborder .suborder_totals {
  float: right;
  padding-right: 20px; }

.order-processing .suborder .wholesale_totals {
  float: left;
  margin-top: 10px;
  width: 125px;
  padding: 2px;
  padding-top: 10px;
  border-radius: 0px 0px 5px 5px;
  margin-top: -10px;
  background: #ebf9fd;
  border: 1px solid #7cbcd1;
  border-top: none; }

.order-processing .suborder .wholesale_totals .right {
  font-size: 12px; }

.order-processing .suborder .suborder_totals .right {
  font-size: 12px; }

.customer-order:not(.wholesale-order) .orderitem {
  min-height: 450px; }

.order-processing .orderitem td {
  padding-bottom: 3px; }

.order-processing .orderitem,
.order-processing .sourcediscount {
  background: #fff;
  border: 1px solid #aaa;
  border-radius: 5px;
  float: left;
  margin-bottom: 5px;
  width: 100%; }

.order-processing .sourcediscounts .sourcediscount {
  border-color: #7cbcd1;
  padding: 3px;
  padding-left: 5px;
  width: 100%; }

.order-processing .sourcediscounts {
  width: 657px; }

.order-processing .sourcediscounts .sourcediscounts-title {
  margin-bottom: 8px;
  font-weight: bold;
  color: grey; }

.order-processing .sourcediscounts .sourcediscount.last-discount {
  margin-bottom: 20px; }

.order-processing .sourcediscounts .sourcediscount.enabled {
  border-color: #388827; }

.order-processing .sourcediscounts .sourcediscount.disabled {
  border-color: #ff0000; }

.order-processing .orderitem.odd {
  background: #fafafa; }

.order-processing .orderitem.last {
  margin-bottom: 0px;
  background: #fafafa; }

.order-processing .customer-order .col1 img {
  border: 1px solid #aaa;
  border-radius: 5px; }

.order-processing .right-bar {
  float: left;
  margin-top: -20px; }

@media (min-height: 900px) {
  .right-bar-fixed {
    position: fixed;
    margin-left: 731px;
    top: 10px; } }

.order-processing .info-box {
  margin: 10px 0px 10px 0px;
  background: #fff;
  border: 1px solid #aaa;
  width: 300px; }

.order-processing .info-box .header {
  padding: 5px;
  font-weight: bold;
  background: #ebf9fd;
  border-bottom: 1px solid #7cbcd1;
  width: 100%; }

.order-processing .info-box .content {
  padding: 5px;
  width: 100%; }

.order-processing .info-box .editable-link {
  color: #333;
  cursor: pointer; }

.order-processing .info-box .editable-link .empty {
  color: #333;
  background: #f6f6f6;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 2px; }

/******************************************
* the address/PATCH section dynamic inputs
*******************************************/
.editableTextHolder {
  float: left;
  height: 25px; }

.editableDropdownHolder {
  float: left; }

.editableDropdownHolder .info-box .content .form-group {
  width: 50%;
  margin-bottom: 0px; }

.info-box .content .input-group-btn .btn {
  line-height: inherit;
  padding: 1px 7px 2px; }

.info-box .content .input-group-btn {
  width: 0px !important; }

/******************************************
* wholesale tab things
*******************************************/
.wholesale-order .col1 {
  height: inherit;
  margin-bottom: 10px; }

/******************************************
* history tab things
*******************************************/
.historyHolder {
  width: 100%; }

.historyElement {
  border-radius: 5px;
  background: #f6f6f6;
  margin-top: 5px;
  padding: 3px;
  border: 1px solid #aaa; }

.historyElement:nth-child(odd) {
  background: #fafafa; }

.historyHeader {
  font-size: 13px;
  color: #aaa; }

.heidi {
  /*** styles for the New Admin panel's User Dashbaord ***/
  /*
A collection of re-usable triangles
*/
  /*
Standardized animation support for keyframe creation (with fallbacks for older browsers)
*/
  /** component styles **/
  /** formatting room tabs **/
  /** formats the space tab titles **/
  /** classes for space tab components */
  /** classes for navigation within spaces **/
  /** classes for the photos and measurements tab **/
  /** classes for floating action buttons **/
  /** classes for the designs tab **/
  /** classes for the custom props tab **/
  /** classes for the vision tab **/
  /** formatting for the users page **/
  /** classes for the products modal **/
  /** classes for genome modal **/
  /* classes for photo zoom modal */
  /** classes for the file upload modal **/
  /** classes for the image lists **/
  /** classes for basic information component **/
  /** classes for external user links component **/
  /** classes for components that involve forms (includes editable fields) **/
  /** classes for the form in the custom products modal **/
  /** high-level styling **/
  /** for dynamic assignment from JS **/
  /** prevents any panel from becoming too small **/
  /* colors for status */
  /** class for tags popover **/
  /** hiding and showing images **/
  /** custom checkbox component **/
  /** Classes for rotating images **/ }
  .heidi .label-success {
    background-color: #067038;
    color: #ffffff;
    font-weight: 500 !important; }
  .heidi .label-warning {
    background-color: #fbd105;
    color: #ffffff;
    font-weight: 500 !important; }
  .heidi .label-danger {
    background-color: #ef4d21;
    color: #ffffff;
    font-weight: 500 !important; }
  .heidi .btn-success {
    background-color: #067038 !important;
    border: none;
    color: #ffffff;
    font-weight: 500; }
    .heidi .btn-success:hover {
      background-color: #046333 !important; }
  .heidi .btn-warning {
    background-color: #fbd105 !important;
    border: none;
    color: #ffffff;
    font-weight: 500; }
    .heidi .btn-warning:hover {
      background-color: #e1bd05 !important; }
  .heidi .btn-danger {
    background-color: #ef4d21 !important;
    border: none;
    color: #ffffff;
    font-weight: 500; }
    .heidi .btn-danger:hover {
      background-color: #d7461d !important; }
  .heidi a {
    color: #4978BC; }
  .heidi .nav-pills > li.active a {
    background-color: #4978BC !important;
    color: #ffffff;
    font-weight: 500; }
    .heidi .nav-pills > li.active a > div.row > div {
      font-weight: 500; }
  .heidi .pagination > li.active a {
    background-color: #4978BC !important;
    border-color: #4978BC !important; }
  .heidi .badge {
    background-color: #534741 !important; }
  .heidi .action-button {
    background-color: #ef4d21 !important;
    border: none;
    color: #ffffff;
    font-weight: 500;
    width: 150px; }
  .heidi .action-button:hover {
    background-color: #d7461d !important; }
  .heidi .action-button.border-blue {
    background-color: #fff !important;
    color: #698bb7;
    border: 1px solid #698bb7;
    width: 150px; }
  .heidi #order_history_table td {
    padding: 2px; }
  .heidi #order_history_table {
    width: 1200px; }
  .heidi #room-tabs #room-tabs-tab-dropdown .caret {
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 8px dashed;
    margin-top: 15px;
    padding-bottom: 39px; }
  .heidi .spaces-tab-dropdown > ul {
    right: 0;
    left: auto;
    padding: 24px 0;
    max-height: 300px;
    overflow-y: auto;
    /* Track */
    /* Handle */
    /* Handle on hover */
    /* Firefox support */
    scrollbar-color: #888 #202020; }
    .heidi .spaces-tab-dropdown > ul::-webkit-scrollbar {
      width: 16px; }
    .heidi .spaces-tab-dropdown > ul::-webkit-scrollbar-track {
      background: inherit; }
    .heidi .spaces-tab-dropdown > ul::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 10px;
      padding: 8px;
      border: 4px solid white; }
    .heidi .spaces-tab-dropdown > ul::-webkit-scrollbar-thumb:hover {
      background: #888; }
  .heidi .tab-title h4 {
    margin-bottom: 3px; }
  .heidi .editable-link {
    background: none;
    border: none;
    color: #4978bc; }
  .heidi .space-tab-component .general-info {
    padding-top: 20px;
    padding-bottom: 15px;
    padding-left: 30px; }
    .heidi .space-tab-component .general-info .right-align-text button {
      margin-bottom: 15px; }
    .heidi .space-tab-component .general-info .header-row {
      padding-bottom: 10px;
      margin-bottom: 10px; }
  .heidi .space-tab-component .order-number {
    padding-left: 16px; }
  .heidi .space-navigation {
    margin-top: 15px; }
    .heidi .space-navigation .space-navigation-content {
      border-top: 1px solid #dddddd; }
    .heidi .space-navigation .space-container-tab-titles {
      min-width: 26px !important; }
  .heidi .photos-and-measurements {
    padding-top: 16px; }
    .heidi .photos-and-measurements > .row {
      margin-top: 20px; }
    .heidi .photos-and-measurements .room-dimensions-header {
      float: left;
      padding-right: 15px; }
    .heidi .photos-and-measurements .measurements-table .table {
      max-width: 250px;
      margin: auto; }
    .heidi .photos-and-measurements #units-dropdown {
      vertical-align: middle; }
      .heidi .photos-and-measurements #units-dropdown > div {
        vertical-align: top;
        width: 100%; }
        .heidi .photos-and-measurements #units-dropdown > div button {
          width: 50%;
          min-width: 42px;
          height: 100%; }
    .heidi .photos-and-measurements .measurements-status {
      text-align: center; }
      .heidi .photos-and-measurements .measurements-status #measurements-dropdown {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-top: 3px;
        padding-bottom: 3px; }
    .heidi .photos-and-measurements .user-photos-header > span {
      position: relative;
      float: left;
      bottom: 10px; }
    .heidi .photos-and-measurements .user-photos-header .btn-toolbar > button {
      float: right; }
    .heidi .photos-and-measurements .user-photos-header .btn-group {
      float: right; }
    .heidi .photos-and-measurements .user-photos-header button {
      height: 32px; }
    .heidi .photos-and-measurements .user-photos-header .upload-button {
      width: 38px; }
    .heidi .photos-and-measurements .approve-all-button {
      background-color: #067038 !important;
      border: none;
      color: #ffffff;
      font-weight: 500;
      border-radius: 0;
      white-space: normal;
      max-width: 220px;
      height: 30px;
      font-size: 14px;
      line-height: 20px;
      overflow-wrap: normal;
      display: flex;
      justify-content: center;
      align-items: center; }
      .heidi .photos-and-measurements .approve-all-button:hover {
        background-color: #067038 !important; }
    .heidi .photos-and-measurements .reject-all-button {
      background: #ef4d21;
      max-width: 220px;
      white-space: normal;
      border-radius: 0;
      height: 30px;
      margin-right: 4px;
      font-size: 14px;
      line-height: 20px;
      color: white;
      border: 1px solid #ef4d21;
      display: flex;
      justify-content: center;
      align-items: center; }
      .heidi .photos-and-measurements .reject-all-button:hover {
        background-color: #ef4d21 !important; }
    .heidi .photos-and-measurements #bg-nested-dropdown {
      margin-right: 4px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center; }
    .heidi .photos-and-measurements #upload-photos-button {
      margin-right: 4px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 4px; }
    .heidi .photos-and-measurements .user-photo-trash-glyph, .heidi .photos-and-measurements .rotate-glyph {
      float: right;
      cursor: pointer; }
      .heidi .photos-and-measurements .user-photo-trash-glyph:hover, .heidi .photos-and-measurements .rotate-glyph:hover {
        color: #ef4d21; }
    .heidi .photos-and-measurements .rotate-glyph {
      margin-right: 5px; }
  .heidi .button-toggler {
    position: relative; }
    .heidi .button-toggler .button-container, .heidi .button-toggler .button-container-modal, .heidi .button-toggler .button-container-thumbnail {
      position: absolute;
      width: 100%;
      text-align: center;
      visibility: hidden;
      transition: visibility 0s, opacity 0.3s linear;
      opacity: 0; }
    .heidi .button-toggler .button-container-modal {
      z-index: 2;
      bottom: 30px; }
    .heidi .button-toggler .button-container-thumbnail {
      bottom: -1px;
      z-index: 1; }
    .heidi .button-toggler .set-as-before-button {
      background-color: #067038 !important;
      border: none;
      color: #ffffff;
      font-weight: 500; }
      .heidi .button-toggler .set-as-before-button:hover {
        background-color: #046333 !important; }
    .heidi .button-toggler .approve-button {
      margin-right: 15px;
      background-color: #067038 !important;
      border: none;
      color: #ffffff;
      font-weight: 500; }
      .heidi .button-toggler .approve-button:hover {
        background-color: #046333 !important; }
    .heidi .button-toggler .reject-button {
      background-color: #ef4d21 !important;
      border: none;
      color: #ffffff;
      font-weight: 500;
      width: 10%;
      margin-left: 15px; }
      .heidi .button-toggler .reject-button:hover {
        background-color: #d7461d !important; }
    .heidi .button-toggler .pending-button {
      background-color: #fbd105 !important;
      border: none;
      color: #ffffff;
      font-weight: 500;
      width: 30%;
      margin-right: 5px; }
      .heidi .button-toggler .pending-button:hover {
        background-color: #e1bd05 !important; }
    .heidi .button-toggler:hover .button-container, .heidi .button-toggler:hover .button-container-modal, .heidi .button-toggler:hover .button-container-thumbnail {
      visibility: visible;
      opacity: 1; }
  .heidi .designs > .row {
    margin-top: 20px; }
  .heidi .designs .dropdown-toggle {
    margin-right: 15px; }
  .heidi .designs h4 {
    float: left;
    margin-right: 20px; }
  .heidi .designs .designs-header > span {
    bottom: 10px; }
  .heidi .designs blockquote {
    float: left; }
    .heidi .designs blockquote h5 {
      margin: 0px;
      padding: 0px; }
  .heidi .designs a {
    text-decoration: none !important; }
  .heidi .custom-props > .row {
    margin-top: 20px; }
  .heidi .custom-props .custom-props-header > span {
    position: absolute;
    float: left;
    bottom: 10px; }
  .heidi .custom-props .custom-props-header button {
    float: right; }
  .heidi .custom-props .custom-props-header .approve-all-button {
    background-color: #067038 !important;
    border: none;
    color: #ffffff;
    font-weight: 500; }
    .heidi .custom-props .custom-props-header .approve-all-button hover {
      background-color: #046333 !important; }
  .heidi .custom-props .custom-props-header .pending-all-button {
    background-color: #fbd105 !important;
    border: none;
    color: #ffffff;
    font-weight: 500; }
    .heidi .custom-props .custom-props-header .pending-all-button:hover {
      background-color: #e1bd05 !important; }
  .heidi .custom-props .image-thumbnail {
    text-align: center;
    width: 94% !important;
    margin: auto; }
  .heidi .vision-details span.answer {
    margin-left: 16px; }
  .heidi .vision-details .form-group.how-to-use {
    margin-bottom: 0px; }
  .heidi .vision-details .radio-inline {
    margin-left: 16px; }
  .heidi .vision-details .tvprefs {
    cursor: pointer;
    color: #4978BC; }
    .heidi .vision-details .tvprefs:hover {
      text-decoration: underline; }
  .heidi .users th a {
    color: black;
    text-decoration: none;
    cursor: pointer !important; }
  .heidi .users .users-header {
    margin-bottom: 20px; }
  .heidi .users .btn-toolbar > .btn-group {
    float: right !important; }
  .heidi .users .filter-title {
    float: right !important;
    margin-top: 7px;
    margin-right: 5px;
    font-weight: 500; }
  .heidi .users th {
    font-weight: 500; }
  .heidi .products-modal .pagination-formatting {
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 10px; }
  .heidi .products-modal .glyph-formatting {
    padding-left: 25px; }
  .heidi .products-modal .grid-item {
    width: 180px;
    height: auto;
    float: left;
    padding-bottom: 10px; }
  .heidi .products-modal .grid {
    background: #ffffff;
    width: 560px;
    margin: auto; }
  .heidi .products-modal .grid:after {
    content: '';
    display: block;
    clear: both; }
  .heidi .genome-modal .circle-scss {
    /*
 * react-circular-progressbar styles
 */ }
    .heidi .genome-modal .circle-scss .CircularProgressbar {
      width: 100%; }
    .heidi .genome-modal .circle-scss .CircularProgressbar .CircularProgressbar-path {
      stroke-linecap: round;
      transition: stroke-dashoffset 0.5s ease 0s; }
    .heidi .genome-modal .circle-scss .CircularProgressbar .CircularProgressbar-trail {
      stroke: #d6d6d6; }
    .heidi .genome-modal .circle-scss .CircularProgressbar .CircularProgressbar-text {
      font-size: 20px;
      dominant-baseline: middle;
      text-anchor: middle; }
    .heidi .genome-modal .circle-scss .blue {
      stroke: #4285f4;
      fill: #4285f4; }
    .heidi .genome-modal .circle-scss .green {
      stroke: #2bbbad;
      fill: #2bbbad; }
    .heidi .genome-modal .circle-scss .purple {
      stroke: #aa66cc;
      fill: #aa66cc; }
  .heidi .genome-modal .circular-graphic-container {
    width: 25vw;
    max-width: 145px;
    height: auto;
    margin: auto;
    padding-bottom: 10px; }
  .heidi .genome-modal .label-padded {
    padding-bottom: 10px; }
  .heidi .genome-modal .genome-info {
    padding-right: 15px;
    float: right; }
  .heidi .photo-modal .modal-header {
    padding-top: 5px;
    padding-bottom: 5px; }
    .heidi .photo-modal .modal-header button {
      border: none;
      margin-right: 4px;
      padding: 5px; }
  .heidi .photo-modal .modal-content {
    position: relative; }
  .heidi .photo-modal .img-modal-thumbnail {
    /* Need to override Chrome's new default image-orientation: from-image */
    image-orientation: none;
    min-height: 200px;
    min-width: 40%;
    max-height: 75vh;
    max-width: 80%;
    background: #fff url("../static/images/ajax-loader.gif") center center no-repeat;
    padding: 8px;
    transition: opacity 0.2s linear; }
  .heidi .photo-modal .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    left: 2%; }
  .heidi .photo-modal .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    right: 2%; }
  .heidi .photo-modal i {
    border: solid #ef4d21;
    border-width: 0 10px 10px 0;
    position: absolute;
    top: 46%;
    padding: 2%;
    height: 4%;
    z-index: 2; }
    .heidi .photo-modal i:hover {
      border: solid #bf3e1b;
      border-width: 0 10px 10px 0;
      cursor: pointer; }
  .heidi .custom-props-modal .custom-prop-modal-header h5 {
    margin-top: 10px;
    margin-bottom: 0px;
    float: left; }
  .heidi .custom-props-modal .save-and-approve {
    margin-top: 15px;
    margin-bottom: 15px; }
  .heidi .custom-props-modal .inline-cp-field-value {
    padding-left: 20px; }
  .heidi .custom-props-modal .tags-label {
    padding-top: 7px;
    padding-right: 0px; }
  .heidi .custom-props-modal .modal-footer {
    padding-top: 5px;
    padding-bottom: 5px; }
  .heidi .custom-props-modal hr {
    margin-top: 5px;
    margin-bottom: 5px; }
  .heidi .custom-props-modal table {
    margin-bottom: 0px; }
    .heidi .custom-props-modal table th {
      font-weight: 500; }
  .heidi .custom-props-modal .separated-rows {
    padding-top: 5px; }
    .heidi .custom-props-modal .separated-rows td {
      max-width: 100px; }
    .heidi .custom-props-modal .separated-rows #units-dropdown button {
      width: 30px; }
  .heidi .custom-props-modal .img-modal-thumbnail {
    /* Need to override Chrome's new default image-orientation: from-image */
    image-orientation: none;
    max-height: 20vh !important;
    min-height: 200px !important;
    min-width: 150px; }
  .heidi .custom-props-modal .checkbox {
    margin-top: 0px; }
    .heidi .custom-props-modal .checkbox label {
      margin-top: 0px;
      font-size: x-large; }
  .heidi .custom-props-modal .delete-prop-button {
    background-color: #ef4d21 !important;
    border: none;
    color: #ffffff;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0px; }
    .heidi .custom-props-modal .delete-prop-button:hover {
      background-color: #d7461d !important; }
  .heidi .custom-props-modal .add-image-button {
    background-color: #067038 !important;
    border: none;
    color: #ffffff;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0px; }
    .heidi .custom-props-modal .add-image-button:hover {
      background-color: #046333 !important; }
  .heidi .custom-props-modal .save-button {
    background-color: #ef4d21 !important;
    border: none;
    color: #ffffff;
    font-weight: 500; }
    .heidi .custom-props-modal .save-button:hover {
      background-color: #d7461d !important; }
  .heidi .custom-props-modal .approve-button {
    background-color: #067038 !important;
    border: none;
    color: #ffffff;
    font-weight: 500; }
    .heidi .custom-props-modal .approve-button:hover {
      background-color: #046333 !important; }
  .heidi .custom-props-modal .hack-vert-center {
    transform: translateY(25%); }
  .heidi .custom-props-modal .input-height-limited {
    height: 30px; }
  .heidi .upload-modal .dropzone-general, .heidi .upload-modal .upload-dropzone, .heidi .upload-modal .upload-dropzone-active, .heidi .upload-modal .upload-dropzone-rejected {
    width: 100%;
    height: 200px;
    border-width: 2px;
    border-style: dashed;
    border-radius: 5px; }
    .heidi .upload-modal .dropzone-general #first-row-upload, .heidi .upload-modal .upload-dropzone #first-row-upload, .heidi .upload-modal .upload-dropzone-active #first-row-upload, .heidi .upload-modal .upload-dropzone-rejected #first-row-upload {
      margin-top: 30px; }
    .heidi .upload-modal .dropzone-general a, .heidi .upload-modal .upload-dropzone a, .heidi .upload-modal .upload-dropzone-active a, .heidi .upload-modal .upload-dropzone-rejected a {
      text-decoration: none;
      cursor: pointer; }
    .heidi .upload-modal .dropzone-general .upload-glyph, .heidi .upload-modal .upload-dropzone .upload-glyph, .heidi .upload-modal .upload-dropzone-active .upload-glyph, .heidi .upload-modal .upload-dropzone-rejected .upload-glyph {
      font-size: 60px; }
    .heidi .upload-modal .dropzone-general h3, .heidi .upload-modal .upload-dropzone h3, .heidi .upload-modal .upload-dropzone-active h3, .heidi .upload-modal .upload-dropzone-rejected h3 {
      margin-top: 0px;
      margin-bottom: 0px; }
  .heidi .upload-modal .upload-dropzone {
    border-color: #aca6a2; }
  .heidi .upload-modal .upload-dropzone-active {
    border-color: #067038; }
  .heidi .upload-modal .upload-dropzone-rejected {
    border-color: #ef4d21; }
  .heidi .upload-modal .image-preview {
    position: relative;
    width: 31%;
    display: inline-block;
    margin-top: 6px;
    margin-right: 3px;
    margin-left: 3px; }
    .heidi .upload-modal .image-preview .trash-glyph {
      position: absolute;
      right: 10px;
      top: 10px;
      background-color: #ef4d21 !important;
      border: none;
      color: #ffffff;
      font-weight: 500;
      padding-top: 3px;
      padding-right: 4px;
      padding-bottom: 0px;
      padding-left: 3px; }
      .heidi .upload-modal .image-preview .trash-glyph:hover {
        background-color: #d7461d !important;
        border: none;
        color: #ffffff;
        font-weight: 500; }
    .heidi .upload-modal .image-preview img {
      display: inline-block; }
  .heidi .upload-modal #preview-row {
    margin-top: 10px; }
  .heidi .product-search-modal .modal-body {
    height: 400px; }
  .heidi .image-list .separation-border {
    margin-right: 15px;
    margin-left: 15px; }
    .heidi .image-list .separation-border .pagination {
      margin-top: 0px;
      margin-bottom: 0px; }
  .heidi .image-list .image-col {
    /* Image flags */ }
    .heidi .image-list .image-col > div {
      padding-right: 10px;
      padding-left: 10px;
      margin-top: 25px;
      position: relative; }
      .heidi .image-list .image-col > div a {
        text-decoration: none !important; }
      .heidi .image-list .image-col > div .thumbnail {
        margin-bottom: 0px; }
    .heidi .image-list .image-col .flag-360 {
      background-image: url("/static/images/360_flag.png"); }
    .heidi .image-list .image-col .flag-empty-room {
      background-image: url("/static/images/empty_room_flag.png"); }
    .heidi .image-list .image-col .flag-custom-prop {
      background-image: url("/static/images/custom_prop_flag.png"); }
    .heidi .image-list .image-col .empty {
      display: none; }
    .heidi .image-list .image-col .image-thumbnail {
      width: 98%;
      margin: auto; }
      .heidi .image-list .image-col .image-thumbnail .thumbnail-header {
        padding-right: 14px;
        padding-left: 7px; }
      .heidi .image-list .image-col .image-thumbnail .thumbnail-footer {
        padding-top: 2px;
        padding-right: 14px;
        padding-left: 7px; }
      .heidi .image-list .image-col .image-thumbnail #favorites-row {
        border: 1px solid #ccc;
        border-radius: 5px; }
        .heidi .image-list .image-col .image-thumbnail #favorites-row:hover {
          border: 1px solid #ef4d21;
          cursor: pointer; }
      .heidi .image-list .image-col .image-thumbnail .image-row img {
        /* Need to override Chrome's new default image-orientation: from-image */
        image-orientation: none;
        background: #fff url("../static/images/ajax-loader.gif") center center no-repeat;
        padding: 8px;
        max-width: 100%;
        max-height: 100%;
        min-height: 200px;
        transition: opacity 0.2s linear; }
        .heidi .image-list .image-col .image-thumbnail .image-row img:hover {
          border: 1px solid #ef4d21;
          cursor: pointer; }
      .heidi .image-list .image-col .image-thumbnail .image-row .image-not-found {
        padding: 8px;
        width: 98%;
        min-height: 200px;
        transition: opacity 0.2s linear;
        background: #fff url("../static/images/image-not-found.png") center center no-repeat; }
        .heidi .image-list .image-col .image-thumbnail .image-row .image-not-found:hover {
          border: 1px solid #ef4d21;
          cursor: pointer; }
  .heidi .quiz-component {
    /* scrolling quiz results with static header */ }
    .heidi .quiz-component .table-fixed tbody {
      max-height: 100px;
      overflow-y: auto;
      width: 100%; }
    .heidi .quiz-component .table-fixed thead,
    .heidi .quiz-component .table-fixed tbody,
    .heidi .quiz-component .table-fixed tr,
    .heidi .quiz-component .table-fixed td,
    .heidi .quiz-component .table-fixed th {
      display: block; }
    .heidi .quiz-component .table-fixed tbody td,
    .heidi .quiz-component .table-fixed thead > tr > th {
      float: left;
      border-bottom-width: 0; }
    .heidi .quiz-component .quiz-badge {
      margin-bottom: 2px;
      margin-left: 3px; }
    .heidi .quiz-component .icon-checkmark {
      background-color: #ef4d21 !important; }
    .heidi .quiz-component .tab-height a {
      min-height: 63px; }
    .heidi .quiz-component .nav-dropdown ul {
      max-height: 200px;
      overflow-y: scroll; }
  .heidi .basic-information .green {
    color: #067038; }
  .heidi .basic-information .red {
    color: #ef4d21; }
  .heidi .external-user-links > a {
    display: block;
    font-size: 16px;
    text-decoration: underline; }
  .heidi .editable-text-field button, .heidi .inline-text-field button, .heidi .dimensions-input button {
    padding-top: 4px;
    padding-right: 7px;
    padding-bottom: 4px;
    padding-left: 7px; }
  .heidi .editable-text-field input, .heidi .inline-text-field input, .heidi .dimensions-input input {
    height: 30px; }
  .heidi .inline-text-field {
    width: 80%;
    margin-top: 3px;
    margin-bottom: 0px; }
  .heidi .editable-dropdown-field {
    width: 80%;
    margin-top: 3px;
    margin-bottom: 0px; }
    .heidi .editable-dropdown-field select {
      height: 30px; }
  .heidi .dimensions-input {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px; }
  .heidi .editable-link {
    cursor: pointer; }
  .heidi .tag-picker .panel {
    margin-bottom: 0px; }
  .heidi .tag-picker .panel-heading {
    padding-top: 5px;
    padding-right: 0px;
    padding-bottom: 5px;
    padding-left: 0px; }
    .heidi .tag-picker .panel-heading .search {
      padding-left: 5px; }
    .heidi .tag-picker .panel-heading .filter-selected {
      font-size: 13px;
      transform: translateY(25%);
      margin-top: 3px;
      padding-right: 0px;
      padding-left: 0px; }
  .heidi .tag-picker .panel-body {
    padding: 0px; }
    .heidi .tag-picker .panel-body .tag-list {
      max-height: 115px;
      font-size: 13px;
      overflow-y: scroll; }
      .heidi .tag-picker .panel-body .tag-list .row {
        margin-top: 2px;
        padding-top: 3px;
        padding-right: 15px;
        padding-bottom: 3px;
        padding-left: 15px; }
        .heidi .tag-picker .panel-body .tag-list .row:hover {
          background-color: #f5f5f5; }
        .heidi .tag-picker .panel-body .tag-list .row .tag-name {
          margin-top: 2px; }
      .heidi .tag-picker .panel-body .tag-list .selected-tag {
        color: #4977bc; }
      .heidi .tag-picker .panel-body .tag-list .checkbox-holder {
        text-align: center;
        padding-right: 2px; }
  .heidi .custom-product-form {
    margin-top: 5px; }
    .heidi .custom-product-form .control-label {
      font-weight: 500; }
    .heidi .custom-product-form .limit-height {
      height: 30px; }
    .heidi .custom-product-form .limit-width {
      max-width: 100%; }
    .heidi .custom-product-form .form-group {
      margin-bottom: 5px; }
    .heidi .custom-product-form .panel-body {
      padding: 5px; }
    .heidi .custom-product-form .checkbox-container {
      height: 30px;
      padding-top: 4px; }
    .heidi .custom-product-form .save-button {
      width: 85px; }
  .heidi #formControlsInternalNotes::placeholder {
    font-style: italic; }
  .heidi div {
    font-family: "proxima-nova", sans-serif;
    font-weight: 300; }
  .heidi b,
  .heidi strong {
    font-weight: 500; }
  .heidi .center-text {
    text-align: center; }
  .heidi .right-align-text {
    text-align: right; }
  .heidi .left-align-text {
    text-align: left; }
  .heidi .padding-10 {
    padding: 10px; }
  .heidi .no-padding {
    padding: 0px !important; }
  @media screen and (max-width: 615px) {
    .heidi .keep-min-width,
    .heidi .products-modal .modal-content,
    .heidi .user-photo-modal .modal-content {
      min-width: 585px;
      overflow-x: auto; } }
  .heidi .panel-body {
    word-wrap: break-word; }
  .heidi .status0,
  .heidi .pending {
    color: #fbd105; }
  .heidi .status1,
  .heidi .rejected {
    color: #ef4d21; }
  .heidi .status2,
  .heidi .approved {
    color: #067038; }
  .heidi .status4,
  .heidi .not-started {
    color: #4977bc; }
  .heidi .status5,
  .heidi .alternative {
    color: #f7acbb; }
  .heidi .product-tags {
    max-width: 100% !important;
    max-height: 50% !important; }
  .heidi .invisible-image {
    visibility: hidden;
    position: absolute;
    opacity: 0; }
  .heidi .visible-image {
    visibility: visible;
    position: relative;
    opacity: 1; }
  .heidi .input-custom-checkbox {
    height: 20px;
    width: 20px;
    padding-top: 0px;
    padding-left: 2px; }
  .heidi .rotated img {
    transform: rotate(180deg); }
